<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">PRODUCE SALES</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button squared variant="primary">
          <b-icon-plus></b-icon-plus> New Sale
        </b-button>
      </b-navbar-nav>
    </b-navbar>


    <div class="page-controls">
      <b-row class="m-0">
        <b-col>

        <b-select v-model="value_chain" text="All produce" class="mr-2 form-select">
          <option value="">All produce</option>
          <option
            v-for="(crop, idx) in value_chains"
            :key="'c' + idx"
            >{{ crop }}</option>
        </b-select>
        <b-dropdown text="All seasons" class="mr-2" variant="light">
          <b-dropdown-item
            >Season 1</b-dropdown-item
          >
        </b-dropdown>
        <b-form class="ml-auto- d-inline-block">
         
            <b-form-input
              placeholder="search Name"
              type="search"
             class="search"
              v-model="searchfield"
            ></b-form-input>
      
        </b-form>
        </b-col>
        <b-col cols="auto">
  
        <div class="sidebar-width text-right">

         <button  class="btn-opt primary">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
           <button class="btn-opt primary">
           <b-icon-printer></b-icon-printer> Print
           </button>
           <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button>

    </div>
        </b-col>
      </b-row>
    </div>
    <b-table :fields="fields" :items="items" >
        <template #cell(actions)="data">
            <b-button variant="outline-primary" squared @click="displayModal(data.item)">View Details</b-button>
        </template>
    </b-table>
  </div>
</template>

<script>
export default {
    data(){
        let value_chains = ["Coffee", "Soybeans", "Maize"];
        let value_chain ="";
        let searchfield ="";
        let fields = [
            "name",
            "produce",
            "quantity",
            {key:"unit_price",label:"Price Per Unit"},
            {key:"amount","label":"Total Amount", tdClass:"fw-bold"},
            "date",
            {key:"recorded_by",label:"Recorded By"},
            {key:"actions", label:"", tdClass:"table-control"}
        ];
        let activeRecord={};
        let items = [
            {
                name:"Aseel Empex",
                produce:"Coffee",
                grades:"3",
                quantity:"2,000,000 Kg",
                unit_price:"3,200",
                amount:"6.496B",
                recorded_by:"Tasha",
                date:"26/04/2021"
            },
            {
                name:"Yash Investments Ltd",
                produce:"Coffee",
                grades:"4",
                quantity:"2,000,000 Kg",
                unit_price:"3,200",
                amount:"6.496B",
                recorded_by:"Tasha",
                date:"26/04/2021"
            },
            {
                name:"Jesa Diary Corporation",
                produce:"Milk",
                grades:"3",
                quantity:"2,000,000 l",
                unit_price:"3200",
                amount:"6.496B",
                recorded_by:"Tasha",
                date:"26/04/2021"
            }
        ];
        
        return {
            value_chain,
            value_chains,
            searchfield,
            fields,
            items,
            activeRecord
        }
    },
    methods:{
        displayModal(item){
            this.activeRecord = item;
        }
    }
}
</script>

<style>

</style>